.container {
    display: flex;
    flex-direction: column;
    border: 2px solid #1b1818;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 20px;
    padding-top: 7px;
    margin-top: 15px;
  }
  
  .container label {
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .container input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  @media (min-width: 768px) {
    .container {
      flex-direction: row;
      flex-wrap: wrap;
    }
  
    .container label {
      flex-basis: 45%;
      margin-right: 20px; /* Add margin between the columns */
    }
  }

  form div h3{
    text-align: center;
  }

  .project-container {
    padding: 10px;
    border: 2px solid #1b1818;
    border-radius: 4px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .project-container label {
    
    margin-top: 3px;
  }

  .myinout{
    margin-bottom: 10px;
  }


  .button-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .button-container button {
    background-color: #f5f5f5;
    color: #333;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button-container button.active {
    background-color: #333;
    color: #fff;
  }
  
  .section-content div {
    display: none;
  }
  
  .section-content div:first-child {
    display: block;
  }
  
  .section-content div.visible {
    display: block;
  }
  