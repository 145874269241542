.typewriter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .typewriter-text {
    overflow: hidden; /* Ensure the text doesn't overflow */
    border-right: 0.15em solid #000; /* Create a typewriter cursor effect */
    white-space: nowrap; /* Prevent line breaks */
    margin: 0 auto; /* Center the text */
    letter-spacing: 0.15em; /* Add some spacing between letters */
    animation: typing 3s steps(40, end), blink-caret 0.75s step-end infinite;
  }
  
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: #000;
    }
  }
  